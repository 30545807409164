<template>
  <div>
    <div class="product-group">
      <div class="form-group">
        <b>Produs</b>
        <input
          type="text"
          class="vInput form-disabled"
          v-model="editedAcquisitionProduct.need.product.name"
          :disabled="true"
        />
      </div>
    </div>
    <div class="product-group">
      <div class="form-group">
        <b>Descriere</b>
        <editor
          key="editedAcquisitionProductDescription"
          v-model="editedAcquisitionProduct.description"
          minHeight="180"
        />
      </div>
    </div>

    <div class="product-group">
      <div class="form-group">
        <b>Valoarea unitară ințială</b>
        <CustomInput
          :value="editedAcquisitionProduct.need.estimatedPriceWoTva"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Valoarea unitară</b>
        <CustomInput v-model="editedAcquisitionProduct.unitValue" />
      </div>
    </div>

    <div class="product-group">
      <div class="form-group">
        <b>Cota TVA ințială</b>
        <input
          type="text"
          class="vInput form-disabled"
          :value="this.acquisitionProduct.need.cotaTva + '%'"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Cotă TVA</b>
        <input
          type="text"
          class="vInput"
          v-model="editedAcquisitionProduct.tvaValue"
          @input="updateTva"
        />
      </div>
    </div>

    <div class="product-group">
      <div class="form-group">
        <b>Cantitate inițială</b>
        <CustomInput
          :value="editedAcquisitionProduct.need.qty"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Cantitate</b>
        <CustomInput v-model="editedAcquisitionProduct.quantity" />
      </div>
    </div>

    <!--  <div class="product-group">
      <div class="form-group">
        <b>Valoarea unitară inițială, cu TVA</b>
        <input
          type="number"
          class="vInput form-disabled"
          v-model="editedAcquisitionProduct.need.estimatedPrice"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Valoarea unitară, cu TVA</b>
        <input
          v-model="editedAcquisitionProduct.unitValueWithTva"
          type="number"
          class="vInput "
        />
      </div>
    </div> -->

    <div class="product-group">
      <div class="form-group">
        <b>Valoarea totală inițială, fără TVA</b>
        <CustomInput
          :value="editedAcquisitionProduct.need.estimatedTotalPriceWoTva"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Valoarea totală, fără TVA</b>
        <CustomInput
          v-model="editedAcquisitionProduct.totalValueWithoutTva"
          :disabled="true"
        />
      </div>
    </div>

    <div class="product-group">
      <div class="form-group">
        <b>Valoarea totală inițială, cu TVA</b>
        <CustomInput
          :value="editedAcquisitionProduct.need.sumNeed"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Valoarea totală, cu TVA</b>
        <CustomInput
          v-model="editedAcquisitionProduct.totalValueWithTva"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/CustomInput";

export default {
  props: {
    acquisitionProduct: { type: Object, required: true },
  },
  components: { CustomInput },

  data() {
    return {
      editedAcquisitionProduct: this.acquisitionProduct,
    };
  },
  watch: {
    editedAcquisitionProduct: {
      handler(val) {
        val.unitValueWithTva =
          (Number(this.removePercentage(val.tvaValue)) / 100) *
            Number(val.unitValue) +
          Number(val.unitValue);

        val.totalValueWithoutTva = Number(val.unitValue) * Number(val.quantity);

        val.totalValueWithTva =
          ((Number(this.removePercentage(val.tvaValue)) / 100) *
            Number(val.unitValue) +
            Number(val.unitValue)) *
          Number(val.quantity);

        this.$emit("input", {
          ...val,
          tvaValue: this.removePercentage(val.tvaValue),
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateTva(event) {
      if (
        event.inputType == "deleteContentBackward" &&
        this.editedAcquisitionProduct.tvaValue
      ) {
        this.editedAcquisitionProduct.tvaValue = this.editedAcquisitionProduct.tvaValue.slice(
          0,
          -1
        );
        this.editedAcquisitionProduct.tvaValue += "%";
        return;
      }

      event.target.value = this.removePercentage(event.target.value);

      this.editedAcquisitionProduct.tvaValue = event.target.value.match(
        /^\d+\.?\d{0,2}$/
      );

      if (this.editedAcquisitionProduct.tvaValue) {
        this.editedAcquisitionProduct.tvaValue += "%";
      }
    },

    removePercentage(val) {
      if (!val) return "";
      return val.replace("%", "");
    },
  },
  created() {
    if (this.acquisitionProduct?.tvaValue) {
      this.acquisitionProduct.tvaValue += "%";
    }
  },
};
</script>
<style lang="scss">
.product-group {
  display: flex;
  gap: 5px;
  .form-group {
    flex: 1;
  }
}
</style>
