export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        edit: false,
        delete: false,
        approve: false,
        reject: false,
      },
    };
  },
  created() {
    const statuses = ["approved", "rejected"];
    const baseRule = "acquisitions";

    this.initPermision(`${baseRule}.get`, "list");
    this.initPermision(`${baseRule}.post`, "create");
    this.initPermision(`${baseRule}.patch`,'edit')
    this.initPermision(`${baseRule}.approve`, "approve", statuses);
    this.initPermision(`${baseRule}.reject`, "reject", statuses);
    this.initPermision(`${baseRule}.delete`, "delete");
  },
};
