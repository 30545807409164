<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">
        {{
          getProcedureTypes[$route.params.procedureType]
            ? getProcedureTypes[$route.params.procedureType].name
            : ""
        }}
        /
        {{
          centralization
            ? centralization.identifier + " - " + centralization.name
            : ""
        }}
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'ACQUISITIONS'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn7="viewProducts"
          @Btn14="viewPdf"
          @Btn2="editItem"
          @Btn3="deleteItem"
          @Btn4="addDocuments"
          @Btn5="closeAcquisition"
          @Btn6="openAddress"
          @Btn42="requestApprove"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "../mixins/acquisitionsPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { ACQUISITIONS, GET_PAAP_CENTRALIZATION_PAGINATED } from "../api.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";
import AcquisitionProducts from "@/components/AcquisitionProducts";
import { mapMutations, mapGetters } from "vuex";
import Command1 from "@/components/Command1";
import { CommandType } from "../common/types/command.type.js";
import AcquisitionDocuments from "@/components/AcquisitionDocuments";

export default {
  name: "Achizitii",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      centralization: null,
      tableHead: [
        {
          title: "Număr achiziție",
          sort: true,
          queryKey: "acqNumber",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Data achiziție",
          queryKey: "acquisitionDate",
          bottomHead: {
            quikFiltre: "date",
          },
        },
        {
          title: "Furnizor",
          queryKey: "institutionProvider",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoare totală fără TVA",
          queryKey: "totalValueWithoutTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cotă TVA",
          queryKey: "tvaValue",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoare totală cu TVA",
          queryKey: "totalValueWithTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "status",
          bottomHead: {
            quikFiltre: "acquisitionStatus",
          },
        },
      ],
      tableModule: (x, y) =>
        ACQUISITIONS.get(
          x,
          y,
          `eq=paapCentralizationId,${this.$route.params.centralization}`
        ),
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    ...mapMutations(["setReferenceIdForPreview"]),

    reload() {
      this.reloadTable++;
    },
    openAddress(item) {
      window.open(item.conectxAddressUrl, "_blank");
    },
    prepareData(row) {
      const preparation = [
        row.acqNumber || "",
        this.toDate(row.acquisitionDate) || "",
        row.institutionProvider?.name || "",
        this.prepareLocaleNumber(row.totalValueWithoutTva) || "",
        this.prepareLocaleNumber(row.tvaValue) || "",
        this.prepareLocaleNumber(row.totalValueWithTva) || "",
        {
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Draft",
          },
          generated: {
            border: "left",
            icon: "fas fa-pencil",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Generat",
          },
          waiting_approve: {
            border: "left",
            icon: "fas fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Așteptare aprobare",
          },
          rejected: {
            border: "left",
            icon: "fas fa-times-circle",
            css: "padding: 0.5rem;color: #ec4a4a;font-size: 2rem;",
            tooltip: "Respins",
          },
          approved: {
            border: "left",
            icon: "fas fa-clipboard-check",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Aprobat",
          },
          closed: {
            border: "left",
            icon: "fas fa-badge-check",
            css: "padding: 0.5rem;color: green;font-size: 2rem;",
            tooltip: "Finalizat",
          },
        }[row.status] || "-",
      ];

      const x = this.PERMISIONS;

      const prepareBtn = [];
      prepareBtn.push([7, "Produse"]);

      if (row.status != "draft") {
        prepareBtn.push(14);
      }
      if (
        row.status != "approved" &&
        row.status != "waiting_approve" &&
        row.acqType != CommandType.CMD3 &&
        row.status != "closed"
      ) {
        prepareBtn.push(2);
      }

      if (row.status == "generated" || row.status == "rejected") {
        prepareBtn.push([
          42,
          "Trimite spre semnare",
          ["fas fa-signal-stream", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      if (row.status == "waiting_approve") {
        prepareBtn.push([
          6,
          "Deschide lucrarea",
          ["fas fa-book", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      if (
        row.status != "approved" &&
        row.status != "waiting_approve" &&
        row.status != "closed"
      ) {
        prepareBtn.push(3);
      }
      if (row.status == "approved") {
        prepareBtn.push([
          4,
          "Documente",
          ["fas fa-file-plus", "font-size:2.2rem; color: #337ab7;"],
        ]);
        prepareBtn.push([
          5,
          "Finalizează achiziția",
          ["fas fa-badge-check", "font-size:2.2rem; color: green;"],
        ]);
      }

      if (row.status == "closed") {
        prepareBtn.push([
          4,
          "Documente",
          ["fas fa-file-plus", "font-size:2.2rem; color: #337ab7;"],
        ]);
      }
      /* 
      if (x.edit === true) {
        prepareBtn.push(2);
      }
      if (x.delete === true) {
        prepareBtn.push(3);
      } */

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },

    viewPdf(data) {
      this.setReferenceIdForPreview({
        data: {
          id: data.id,
        },
        origin: "ACQUISITION_PDF",
      });
    },

    changeStatus(data, status) {
      const error = (msg) => {
        this.$toastr.e(msg || "Eroare semanre.");
        this.reload();
        this.setLoad();
      };

      this.setSafeLoad(12000);
      ACQUISITIONS.approve(data.id, { status })
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s("Acțiune efectuată cu succes.");

            this.reload();
            this.setLoad();
          } else {
            error(res.response);
          }
        })
        .catch(error);
    },

    requestApprove(data) {
      const error = (msg) => {
        this.$toastr.e(msg || "Eroare semanre.");
        this.reload();
        this.setLoad();
      };

      this.setSafeLoad(12000);
      ACQUISITIONS.requestApprove(data.id)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            window.open(res.data.result.url, "_blank");
            this.reload();
            this.setLoad();
          } else {
            error(res.response);
          }
        })
        .catch(error);
    },

    viewProducts(item) {
      this.$modal.show(
        DialogModal,
        {
          target: "AcquisitionProducts",
          title: "Produse",
          inputs: [
            {
              id: "acquisitionProducts",
              type: "component",
              component: AcquisitionProducts,
              componentProps: { acquisition: item },
            },
          ],
          closeBtnText: "Anulează",

          button: {
            type: 2,
            value: "Generează",
            disabled:
              item.status == "waiting_approve" ||
              item.status == "approved" ||
              item.status == "closed",
            handler: (sendData) => {
              const data = sendData.acquisitionProducts;

              const error = (msg) => {
                this.$toastr.e(msg || "Generarea a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              ACQUISITIONS.generate(data.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Generarea achiziția a fost efectuată.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },

          customClass: "custom-size",
        },
        {
          name: "AcquisitionProducts",
          adaptive: true,
          width: "100%",
          height: "100%",
        }
      );
    },
    async checkProduct(item) {
      if (!item) return;
      this.$router.replace(this.$route.path);
      this.viewProducts({ id: item });
    },

    editItem(item) {
      this.$modal.show(
        DialogModal,
        {
          target: item.acqType,
          title: this.acquisitionTitle[item.acqType],
          inputs: [
            {
              id: "cmd",
              type: "component",
              component: Command1,
              componentProps: { initial: { ...item }, acqType: item.acqType },
            },
          ],
          acceptFinish(data) {
            const cmd = data.cmd;
            for (const key in cmd) {
              if (!cmd[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Salvarea achiziției a eșuat.");
              };

              ACQUISITIONS.edit(item.id, {
                ...data.cmd,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Achiziția a fost salvată.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: item.acqType,
          adaptive: true,
          width: "650px",
          height: "570px",
        }
      );
    },

    deleteItem(item) {
      if (!Number.isInteger(item?.id)) {
        return;
      }

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteAcquisition",
          title: "Șterge achiziție",
          inputs: [],
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Achiziția nu a fost ștearsă.");
                this.reload();
                this.setLoad();
              };

              this.setLoad(true);
              ACQUISITIONS.delete(item.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Achiziți a fost ștearsă.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteAcquisition",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    addDocuments(item) {
      this.$modal.show(
        DialogModal,
        {
          target: "AddDocuments",
          title: "Adaugare document",
          inputs: [
            {
              id: "acquisitionFiles",
              type: "component",
              component: AcquisitionDocuments,
              componentProps: { acquisition: item },
              errReason: "Nu a fost încărcat nici un fișier.",
            },
          ],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Documentele nu a fost încărcate.");
                this.reload();
                this.setLoad();
              };

              this.setLoad(true);

              const sendData = new FormData();
              data.acquisitionFiles
                .filter((e) => !e.deleted)
                .forEach((file) => {
                  sendData.append("files", file.file);
                });

              ACQUISITIONS.addDocuments(item.id, sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Achiziția a fost salvată.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
          customClass: "acquisition-docs",
        },
        {
          name: "AddDocuments",
          adaptive: true,
          width: "1000",
          height: "1000",
        }
      );
    },
    closeAcquisition(item) {
      this.$modal.show(
        DialogModal,
        {
          target: "CloseAcquisitionDocument",
          title: "Doriți să finalizați achiziția?",
          inputs: [],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Eroare la finalizarea achiziției.");
                this.reload();
                this.setLoad();
              };

              this.setLoad(true);
              ACQUISITIONS.closeAcquisition(item.id, { status: "closed" })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Achiziția a fost finalizată.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "CloseAcquisitionDocument",
          adaptive: true,
          width: "550",
          height: "300",
        }
      );
    },
  },

  computed: {
    ...mapGetters(["getProcedureTypes"]),
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    this.checkProduct(this.$route.query.aq);

    const canViewButtonsColumn = ["edit", "delete"];

    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }

    GET_PAAP_CENTRALIZATION_PAGINATED(
      1,
      1,
      `eq=id,${this.$route.params.centralization}`
    ).then((res) => {
      this.centralization = res.data.result[0];
      const path = this.$route.path;
      const val = path.slice(0, path.lastIndexOf("/"));

      this.$root.$emit("select-item", val.slice(0, val.lastIndexOf("/")));
    });
  },
};
</script>
<style>
.acquisition-docs {
  max-width: 1200px !important;
  max-height: 1200px !important;
}
</style>
