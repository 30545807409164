<template>
  <div class="form-group" :class="classes" :style="styles">
    <img src="@/assets/media/remove.svg" alt="" style="display: none;" />
    <b v-if="label">{{ label }} <slot name="label-right"></slot></b>
    <input
      ref="FileInput"
      type="file"
      :accept="allowExtensions.join(',')"
      :multiple="max > 1"
      id="addFileForNeeds"
      @change="getFile"
      class="vInput"
    />
    <div class="vInputLabelParent">
      <span v-tooltip="maxFiles ? `Se pot atașa maxim: ${max} fișiere.` : ''">
        <label
          class="vInputLabel"
          for="addFileForNeeds"
          :style="maxFiles ? 'pointer-events: none;' : ''"
          ><span>Atașează</span>
          <img src="@/assets/media/attach_file.svg" alt=""
        /></label>
      </span>
      <img src="@/assets/media/remove.svg" style="display: none;" alt="" />
      <span
        v-for="file in files"
        :key="file.key"
        @click="delFile(file.index)"
        class="vInputLabelFile"
      >
        <span>{{ file.file.name }}</span>
        <img src="@/assets/media/remove.svg" alt="" />
      </span>
    </div>
    <small class="form-text text-muted"
      >Extensii permise
      <i
        v-tooltip.bottom="allowExtensions.join(', ')"
        class="fas fa-info-circle"
      ></i
    ></small>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Documente',
    },
    signDeleted: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: [Object, String],
      default: '',
    },
    classes: {
      type: [Object, String, Array],
      default: '',
    },
    max: {
      type: Number,
      default: 20,
    },
    allowExtensions: {
      type: Array,
      default: () => ['.doc', '.docx', 'xls', 'xlsx', '.pptx', 'txt', '.ppt', '.pdf', '.bmp', '.png', '.jpg', '.jpeg']
    }
  },
  computed: {
    files() {
      return Array.isArray(this.value)
        ? this.value.map((e, i) => ({ ...e, index: i })).filter(e => !e.deleted)
        : []
    },
    maxFiles() {
      return this.files.length >= this.max
    },
  },
  methods: {
    up(x) {
      this.$emit('input', x)
    },
    clearInput() {
      try {
        this.$refs['FileInput']['value'] = ''
      } catch (err) {
        console.error(err)
      }
    },
    getFile(event) {
      if (!event?.target?.files) {
        return
      }

      const prepareFiles = []
      let haveBeenAdded = 0

      const x = Array.from(event.target.files)
      for (const file of x) {
        const key = `${file.size}|${file.lastModified}|${file.name}`
        if (this.files.length + prepareFiles.length >= this.max) {
          this.$toastr.w({
            msg: `Numărul maxim de fișiere este: ${this.max}.`,
            preventDuplicates: true,
          })
          break
        }
        if (file?.size > 10485760) {
          this.$toastr.w({
            msg: 'Mărimea maximă pentru un fișier nu poate depăși 10 MB.',
            preventDuplicates: true,
          })
          continue
        }
        if (!this.files.find(e => e.key === key)) {
          prepareFiles.push({
            file,
            key,
            deleted: false,
          })
        } else {
          haveBeenAdded++
        }
      }

      this.clearInput()

      if (prepareFiles.length) {
        // this.up(this.files.concat(prepareFiles))
        this.value.push(...prepareFiles)
        if (haveBeenAdded) {
          this.$toastr.w('Unele fișiere selectate de dvs. deja există!')
          return
        }
      }

      if (haveBeenAdded) {
        this.$toastr.w(
          (haveBeenAdded === 1 ? 'Fișierul selectat' : 'Fișierele selectate') +
            ' de dvs. deja există!'
        )
      }
    },
    delFile(index) {
      if (this.signDeleted) this.$set(this.value[index], 'deleted', true)
      else this.value.splice(index, 1)
    },
  },
}
</script>

<style></style>
