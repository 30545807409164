export const CommandType = {
  CMD1: "cmd1",
  CMD1_A2: "cmd1_a2",
  CMD31: "cmd31",
  CRT1: "crt1",
  CRT1_A2: "crt1_a2",
  CRT31: "crt31",
  CMD3: "cmd3",
  CMD4: "cmd4",
};
