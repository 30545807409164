

export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        edit: false,
        delete: false
      }
    }
  },
  created() {
    const baseRule = 'acquisition-products'

    this.initPermision(`${baseRule}.get`,'list')
    this.initPermision(`${baseRule}.post`,'create')
    this.initPermision(`${baseRule}.patch`,'edit')
    this.initPermision(`${baseRule}.delete`,'delete')
  }
}